import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { Flex, Image } from "@chakra-ui/react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import Cookies from "js-cookie";

import "./style.css";

function Layout() {
    useEffect(() => {
      localStorage.setItem('chakra-ui-color-mode', 'light');
        const isFirstVisit = Cookies.get("isFirstVisit");

        if (!isFirstVisit) {
            const initialAcceptedItems = [
                { title: "tytul1", artist: "wykonawca1", link: "https://www.youtube.com/" },
                { title: "tytul2", artist: "wykonawca2", link: "https://www.youtube.com/" },
                { title: "tytul3", artist: "wykonawca3", link: "https://www.youtube.com/" }
            ];
            const initialItemsToAccept = [
                { title: "tytul4", artist: "wykonawca4", link: "https://www.youtube.com/" },
                { title: "tytul5", artist: "wykonawca5", link: "https://www.youtube.com/" },
                { title: "tytul6", artist: "wykonawca6", link: "https://www.youtube.com/" }
            ];

            Cookies.set("accepted-items", JSON.stringify(initialAcceptedItems));
            Cookies.set("items-to-accept", JSON.stringify(initialItemsToAccept));

            Cookies.set("isFirstVisit", "true");
        }
    }, []);

  return (
    <>
      <Outlet />
      <ToastContainer position="bottom-right" />
      <Flex
        w={"100vw"}
        h={"100vh"}
        position={"fixed"}
        top={0}
        left={0}
        zIndex={-1}
      >
        <Image src="/bg.png" objectFit={"cover"} w={"100%"} h={"100%"} />
      </Flex>
    </>
  );
}

export default Layout;
