const { Flex, Text, Link, Button } = require("@chakra-ui/react")


function Card({title, artist, link, index, onAccept, onReject}) {

    const handleClickAccept = () => {
        console.log(index)
        onAccept(index);
    }

    const handleClickReject = () => {
        onReject(index);
    }


    return (
        <Flex direction={'column'} backgroundColor={'gray.100'} p={"20px"} borderRadius={"8px"} boxShadow={"xl"} width={"100%"}>
            <Flex direction={"column"}>
                <Text fontSize={"2xl"} fontWeight={"bold"}>Tytuł utworu</Text>
                <Text fontSize={"xl"} fontWeight={"medium"} ml={"10px"}>{title}</Text>
            </Flex>
            <Flex direction={"column"}>
                <Text fontSize={"2xl"} fontWeight={"bold"}>Wykonawca utworu</Text>
                <Text fontSize={"xl"} fontWeight={"medium"} ml={"10px"}>{artist}</Text>
            </Flex>
            <Flex direction={"column"}>
                <Text fontSize={"2xl"} fontWeight={"bold"}>Link do utworu</Text>
                <Link fontSize={"xl"} fontWeight={"medium"} ml={"10px"} textDecoration={"underline"} href={"#"}>{link}</Link>
            </Flex>
            <Flex direction={"column"} gap={"5px"} p="10px 0px" w={"100%"}>
                <Flex w={"100%"} gap={"10px"}>
                    <Button colorScheme="green" w={"50%"} onClick={handleClickAccept}>Zaakceptuj</Button> 
                    <Button colorScheme="red" w={"50%"} onClick={handleClickReject}>Odrzuć</Button>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Card