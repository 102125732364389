import Navbar from "./Navbar";
import { toast } from "react-toastify";
import {
    Flex,
    Text,
    TableContainer,
    Td,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    FormControl,
    FormLabel,
    Input,
    Box,
    Tfoot,
    Link,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

function App() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        const storedItems = Cookies.get("accepted-items");
        if (storedItems) {
            setData(JSON.parse(storedItems));
        }
    }, []);

    useEffect(() => {
        if (search === "") {
            setFilteredData(data);
            return;
        }
        setFilteredData(data.filter((item) => item.title.includes(search)));
    }, [search, data]);

    const deleteTrack = (index) => () => {
        const updatedData = data.filter((item, i) => i !== index);
        setData(updatedData);
        Cookies.set("accepted-items", JSON.stringify(updatedData));
        toast.success("Utwór został usunięty");
    };

    return (
        <>
            <Navbar current={"list"} />
            <Flex justifyContent={"center"} p={4}>
                <Text fontSize={["3xl", "4xl", "5xl"]} fontWeight={"bold"}>
                    Aktualna lista utworów
                </Text>
            </Flex>
            <Flex
                w={"100vw"}
                justifyContent={"center"}
                alignItems={"center"}
                p={[4, 8, 12]}
            >
                <Flex
                    w={["90vw", "80vw"]}
                    justifyContent={"center"}
                    alignItems={"center"}
                    backgroundColor={"gray.100"}
                    borderRadius={"10px"}
                    p={4}
                    boxShadow={"xl"}
                    direction={"column"}
                >
                    <Flex w="100%" p={4}>
                        <FormControl w={["100%", "50%", "20%"]}>
                            <FormLabel fontSize={"sm"} fontWeight={"medium"}>
                                Wyszukaj utwór
                            </FormLabel>
                            <Input
                                variant={"filled"}
                                bgColor={"#fff"}
                                h="30px"
                                borderRadius={"10px"}
                                placeholder="Tytuł utworu"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                        </FormControl>
                    </Flex>
                    <Flex
                        backgroundColor={"white"}
                        borderRadius={"10px"}
                        p={4}
                        w={"98%"}
                        direction={"column"}
                        gap={4}
                        boxShadow={"xl"}
                    >
                        <TableContainer w={"100%"}>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Tytuł</Th>
                                        <Th>Wykonawca</Th>
                                        <Th isNumeric>Czas trwania</Th>
                                        <Th isNumeric>Usuń</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {filteredData.map((item, index) => (
                                        <Tr key={index}>
                                            <Td>{item.title}</Td>
                                            <Td>{item.artist}</Td>
                                            <Td isNumeric>3:00</Td>
                                            <Td isNumeric>
                                                <Text
                                                    as={Link}
                                                    fontSize={"xl"}
                                                    fontWeight={"bold"}
                                                    color={"red"}
                                                    onClick={deleteTrack(index)}
                                                >
                                                    X
                                                </Text>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                                <Tfoot>
                                    <Tr>
                                        <Th>Tytuł</Th>
                                        <Th>Wykonawca</Th>
                                        <Th isNumeric>Czas trwania</Th>
                                        <Th isNumeric>Usuń</Th>
                                    </Tr>
                                </Tfoot>
                            </Table>
                        </TableContainer>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}

export default App;