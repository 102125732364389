import Navbar from "./Navbar";
import CardAccept from "./CardAccept";
import { toast } from "react-toastify";
import { Flex, Text, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

function AcceptList() {
    const [items, setItems] = useState([]);
    const [acceptedItems, setAcceptedItems] = useState([]);
    
    useEffect(() => {
        const storedItems = Cookies.get('items-to-accept');
        if (storedItems) {
          setItems(JSON.parse(storedItems));
        }
        const storedAcceptedItems = Cookies.get('accepted-items');
        if (storedAcceptedItems) {
          setAcceptedItems(JSON.parse(storedAcceptedItems));
        }
      }, []);

    const handleAccept = (key) => {
        console.log(key);
        const itemToAccept = items[key];
        if (itemToAccept) {
            const updatedAcceptedItems = [...acceptedItems, itemToAccept];
            setAcceptedItems(updatedAcceptedItems);
            Cookies.set("accepted-items", JSON.stringify(updatedAcceptedItems));

            const updatedItems = items.filter((item, index) => index !== key);
            setItems(updatedItems);
            Cookies.set("items-to-accept", JSON.stringify(updatedItems));
            toast.success("Utwór został zaakceptowany");
        }
    };

    const handleReject = (key) => {
        const updatedItems = items.filter((item, index) => index !== key);
        setItems(updatedItems);
        Cookies.set("items-to-accept", JSON.stringify(updatedItems));
        toast.error("Utwór został odrzucony");
    }

    const padding = useBreakpointValue({ base: "20px", md: "20px 200px" });
    const columns = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });
    const gap = useBreakpointValue({ base: "10px", md: "30px" });

    return (
        <>
        <Navbar current={"accept"} />
            <Flex w={"90vw"} p={padding} justifyContent={"left"} alignItems={"center"}>
                <Text fontSize={"2xl"} fontWeight={"bold"}>{items.length} Utwor(ów) do akceptacji</Text>
            </Flex>
            <SimpleGrid columns={columns} gap={gap} p={padding} alignItems={"center"} >
                {items.map((item, index) => (
                    <CardAccept title={item.title} artist={item.artist} link={item.link} index={index} key={index} onAccept={handleAccept} onReject={handleReject} />
                ))}
            </SimpleGrid>
        </>
    )
}

export default AcceptList;