const { Flex, Avatar, Text, Button, Link } = require("@chakra-ui/react");
const { Icon } = require("@chakra-ui/react");
const { FaPlay, FaCheck, FaList, FaHome } = require("react-icons/fa");

const PlayerIcon = FaPlay;
const AcceptIcon = FaCheck;
const ListIcon = FaList;
const HomeIcon = FaHome;


function Navbar({ pv, current }) {
    return (
        <Flex w={'100vw'} justifyContent={"center"} alignItems={"center"} p={"20px 0px"}>
            <Flex w={"90vw"} justifyContent={"space-between"} alignItems={"center"} bg={"gray.100"} p={"10px"} borderRadius={"8px"} boxShadow={"xl"}>
                <Flex alignItems={"center"} gap={"10px"}>
                    <Avatar src='/logo.png' />
                    <Text fontWeight={"bold"} fontSize={"2xl"} display={{ base: "none", md: "block" }}>Radiowęzeł FUMu</Text>
                </Flex>
                {!pv && (
                    <Flex gap={"20px"}>
                        <Button as={Link} colorScheme={"purple"} variant={current === "player" ? "solid" : "outline"} href="/manage/player">
                            <Flex alignItems={"center"} gap={"5px"}>
                                <Icon as={PlayerIcon} display={{ base: "block", md: "none" }} />
                                <Text display={{ base: "none", md: "block" }}>Odtwarzacz</Text>
                            </Flex>
                        </Button>
                        <Button as={Link} colorScheme={"purple"} variant={current === "accept" ? "solid" : "outline"} href="/manage/accept">
                            <Flex alignItems={"center"} gap={"5px"}>
                                <Icon as={AcceptIcon} display={{ base: "block", md: "none" }} />
                                <Text display={{ base: "none", md: "block" }}>Akceptacja utworów</Text>
                            </Flex>
                        </Button>
                        <Button as={Link} colorScheme={"purple"} variant={current === "list" ? "solid" : "outline"} href="/list">
                            <Flex alignItems={"center"} gap={"5px"}>
                                <Icon as={ListIcon} display={{ base: "block", md: "none" }} />
                                <Text display={{ base: "none", md: "block" }}>Lista utworów</Text>
                            </Flex>
                        </Button>
                        <Button as={Link} colorScheme={"purple"} variant={current === "home" ? "solid" : "outline"} href="/">
                            <Flex alignItems={"center"} gap={"5px"}>
                                <Icon as={HomeIcon} display={{ base: "block", md: "none" }} />
                                <Text display={{ base: "none", md: "block" }}>Strona Główna</Text>
                            </Flex>
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Flex>
    )
}

export default Navbar;